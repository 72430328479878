import React from 'react';
import { toastSuccess, toastError } from '../services/toastService';
import logger from '../services/logService';
import Joi from 'joi-browser';
import Form from './common/form';
import PageTitle from './common/PageTitle';
import { getUsergroup, updateUsergroup, saveUsergroup } from '../services/usergroupService';
import Translation from '../services/translationService';

class UsergroupForm extends Form {
    state = {
        data: { name:'', description: '', is_active: true, chain_ids:[], region_ids:[], branch_ids:[], report_ids:[], kpi_ids:[], c_dashboard_ids:[], s_dashboard_ids:[] },
        id: '',
        branches: [],
        regions: [],
        chains: [],
        users: [],
        reports: [],
        kpis: [],
        c_dashboards: [],
        s_dashboards: [],
        errors: {}
    }

    schema = {
        name: Joi.string().required().min(1).label('Naam'),
        description: Joi.string().empty('').max(255).label('Description'),
        is_active: Joi.boolean().required().label('Active'),
        reset_password: Joi.number().optional().empty(0).label('Auto-reset'),
        branch_ids: Joi.array().items(Joi.string()),
        region_ids: Joi.array().items(Joi.string()),
        chain_ids: Joi.array().items(Joi.string()),
        report_ids: Joi.array().items(Joi.string()),
        kpi_ids: Joi.array().items(Joi.string()),
        c_dashboard_ids: Joi.array().items(Joi.string()),
        s_dashboard_ids: Joi.array().items(Joi.string())
    };
    
    async componentDidMount() {
        try {
            const groupId = window.location.href.split('manageusergroups/')[1];
            const { data } = await getUsergroup(groupId);
            
            if (groupId === "new") {
                this.setState({ chains: data.chains, regions: data.regions, branches: data.branches, reports: data.reports, kpis: data.kpis, c_dashboards: data.c_dashboards, s_dashboards: data.s_dashboards });
            } else {
                this.setState({ id: groupId, chains: data.chains, regions: data.regions, branches: data.branches, reports: data.reports, kpis: data.kpis, c_dashboards: data.c_dashboards, s_dashboards: data.s_dashboards, users: data.users, data: { name:data.name, description: data.description, reset_password: data.reset_password, is_active: data.is_active, chain_ids: data.chain_ids, region_ids: data.region_ids, branch_ids: data.branch_ids, report_ids: data.report_ids, kpi_ids: data.kpi_ids, c_dashboard_ids: data.c_dashboard_ids, s_dashboard_ids: data.s_dashboard_ids } });
            }
        } catch (ex) {
            toastError(ex?.response?.data || <Translation>unexpected-error</Translation>);
        }
    }

    doSubmit = async () => {
        if (window.location.href.split('manageusergroups/')[1] === 'new') {
            try {
                await saveUsergroup(this.state);
                window.location = "/manageusergroups";
            }
            catch (ex) {
                if (ex.response) {
                    toastError(ex.response.data);
                }
                logger.log(ex);
            }
        } else {
            try {
                await updateUsergroup(this.state);
                toastSuccess(<Translation>saved-success</Translation>);
            }
            catch (ex) {
                if (ex.response) {
                    toastError(ex.response.data);
                }
                logger.log(ex);
            }
        }
    }

    render() {
        const boolnew = window.location.href.split('manageusergroups/')[1] === "new";
        return (
            <main className="container-fluid">
                <PageTitle title="usergroup" saveButton={{label: 'save', div: "d-md-flex justify-content-md-end", validate: this.validate, submit: this.handleSubmit}} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-dp">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit} >
                                    {this.renderInput("name", "name", "text")}
                                    {this.renderInput("description", "description", "text")}
                                    {this.renderInput("reset_password", "auto-reset-password", "text")}
                                    {this.renderCheckbox("is_active", "active")}
                                    {this.renderSelectForm("chains", "chain", "chain_ids","label")}
                                    {this.renderSelectForm("regions", "region", "region_ids", "label")}
                                    {this.renderSelectForm("branches", "branch", "branch_ids", "label")}
                                    {this.renderSelectForm("reports", "report", "report_ids", "name")}
                                    {this.renderSelectForm("kpis", "kpi", "kpi_ids", "kpi_id", false)}
                                    {this.renderSelectForm("c_dashboards", "c-dbs", "c_dashboard_ids", "title", false)}
                                    {this.renderSelectForm("s_dashboards", "s-dbs", "s_dashboard_ids", "title", false)}
                                    {this.renderButton("save")}
                                    { boolnew ? null : this.renderTable("users", "linked-users", [{'datalabel':'email','colname':'email', 'type':'text'},{'datalabel':'full_name','colname':'name', 'type':'text'},{'datalabel':'_id','colname':'action', 'type':'link-user-1'}])}
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        );
    }
}

export default UsergroupForm;
