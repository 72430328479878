import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { createColors } from './calculateKPI';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const graphoptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  scales: {
    y: {
        ticks: { autoSkip: true},
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'right',
    },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart',
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let show = context.dataset.show[context.dataIndex];
          return show ? show : context.formattedValue;
        }
      }
    }
  },
  interaction: {
    intersect: false,
    mode: 'y'
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];

export const data_origineel = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};

export default function BarChartHorizontal({ data, label='label', amount='amount', options}) {
  if (options.kpi_id === "emp_score") options.colors = "blues-1";
  let data1 = {
    labels: data.map((x) => x[label]),
    datasets: [
      {
        data: data.map((x) => parseFloat(x[amount])),
        show: data.map((x) => x['show'] ),
        borderColor: createColors(options.colors,true,data.length),
        backgroundColor: createColors(options.colors,false,data.length),
        // borderColor: `rgb(53, 162, 235)`,
        // backgroundColor: `rgba(53, 162, 235, 0.7)`,
        // borderColor: `rgb(${color_sel})`,
        // backgroundColor: `rgba(${color_sel}, 0.5)`,
      }
    ]
  };

  return <Bar className="chart" options={graphoptions} height={225} data={data1} />;
}

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => Math.floor(Math.random() * 1000)),
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => Math.floor(Math.random() * 1000)),
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

