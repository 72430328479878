import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { formatUnit } from './calculateKPI';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const graphoptions = {
  indexAxis: 'x',
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'right',
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart - Stacked',
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) label += ': ';
          if (context.dataset.unit) {
            label += formatUnit(context.parsed.y,context.dataset.unit);
          }
          return label;
        }
      }
    }
  },
  responsive: true,
  interaction: {
    mode: 'nearest',
    intersect: false,
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data_origineel = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: 'rgb(75, 192, 192)',
    },
    {
      label: 'Dataset 3',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: 'rgb(53, 162, 235)',
    },
  ],
};

const colors = ['rgb(255, 99, 132)','rgb(53, 162, 235)','rgb(75, 192, 192)','rgb(255,235,59)','rgb(255,152,0)','rgb(255,87,34)','rgb(2,136,209)','rgb(128,0,0)','rgb(139,0,0)','rgb(165,42,42)','rgb(178,34,34)','rgb(220,20,60)','rgb(255,0,0)','rgb(255,99,71)','rgb(255,127,80)','rgb(205,92,92)','rgb(240,128,128)','rgb(233,150,122)','rgb(250,128,114)','rgb(255,160,122)','rgb(255,69,0)','rgb(255,140,0)','rgb(255,165,0)','rgb(255,215,0)','rgb(184,134,11)','rgb(218,165,32)','rgb(238,232,170)','rgb(189,183,107)','rgb(240,230,140)','rgb(128,128,0)','rgb(255,255,0)','rgb(154,205,50)','rgb(85,107,47)','rgb(107,142,35)','rgb(124,252,0)','rgb(127,255,0)','rgb(173,255,47)','rgb(0,100,0)','rgb(0,128,0)','rgb(34,139,34)','rgb(0,255,0)','rgb(50,205,50)','rgb(144,238,144)','rgb(152,251,152)','rgb(143,188,143)','rgb(0,250,154)','rgb(0,255,127)','rgb(46,139,87)','rgb(102,205,170)','rgb(60,179,113)','rgb(32,178,170)','rgb(47,79,79)','rgb(0,128,128)','rgb(0,139,139)','rgb(0,255,255)','rgb(0,255,255)','rgb(224,255,255)','rgb(0,206,209)','rgb(64,224,208)','rgb(72,209,204)','rgb(175,238,238)','rgb(127,255,212)','rgb(176,224,230)','rgb(95,158,160)','rgb(70,130,180)','rgb(100,149,237)','rgb(0,191,255)','rgb(30,144,255)','rgb(173,216,230)','rgb(135,206,235)','rgb(135,206,250)','rgb(25,25,112)','rgb(0,0,128)','rgb(0,0,139)','rgb(0,0,205)','rgb(0,0,255)','rgb(65,105,225)','rgb(138,43,226)','rgb(75,0,130)','rgb(72,61,139)','rgb(106,90,205)','rgb(123,104,238)','rgb(147,112,219)','rgb(139,0,139)','rgb(148,0,211)','rgb(153,50,204)','rgb(186,85,211)','rgb(128,0,128)','rgb(216,191,216)','rgb(221,160,221)','rgb(238,130,238)','rgb(255,0,255)','rgb(218,112,214)','rgb(199,21,133)','rgb(219,112,147)','rgb(255,20,147)','rgb(255,105,180)','rgb(255,182,193)','rgb(255,192,203)'];

export default function BarChartStackedVert({data}) {
  data.datasets = data.datasets.map((d, i) => {
    d['borderColor'] = colors[i];
    d['backgroundColor'] = colors[i];
    return d;
  });
  
  return <Bar className="chart" options={graphoptions} height={225} data={data} />;
}