import React from 'react';
import _ from 'lodash';
import { classWidth } from '../../services/calculateService';
import { chart } from '../common/charts2/chartSelections';
import Trend from '../common/charts2/trend';
import Info from './info';

function branchfilter(branches,data) {
    let datac = _.cloneDeep(data);
    if (branches && datac) {
        for (const key in datac) {
            if (key !== 'trend_dates') {
                // Filter data on selected branches
                datac[key] = _.filter(datac[key], o => { return branches.includes(o.branch) } );
            }
        }
    }
    return datac;
}

function Widgets({ dashboard, data, trends, trends2, selection, branches, loading, loadingt }) {
    if (Object.keys(dashboard).length === 0) return null;

    let dataf = {...data};
    if (branches && dataf) {
        for (const key in dataf) {
            // Filter data on selected branches
            dataf[key] = _.filter(dataf[key], o => { return branches.includes(o.branch) } );
        }
    }

    let trendsf = _.cloneDeep(trends);
    if (branches && trendsf) {
        for (const key in trendsf) {
            if (key !== 'trend_dates') {
                // Filter data on selected branches
                trendsf[key] = _.filter(trendsf[key], o => { return branches.includes(o.branch) } );
            }
        }
    }

    let trendsf2 = trends2 && branchfilter(branches,trends2);

    let i = 0;
    dashboard.widgets = _.sortBy(dashboard.widgets, [function(o) {
        o.uid = i;
        i += 1;
        return o.id; 
    }]);

    return (
        <div className="row" key='dashboards'>
            {dashboard.widgets.map(w => w.kpi_id ? 
                <div className={classWidth(w.width)} key={w.uid} >
                    <div className="card card-dp">
                        <div className="card-body card-widget">
                            <div className="row"><div className="col-12"><p className="card-dp-h2">{w.title} </p></div><div className="widget-i"><Info title={w.kpi_id} /></div></div>
                            <div className={`div-widget ${w.height ? `div-widget-${w.height}` : ''}`}>{ chart(dataf, w)}
                            { w.trend && w.height && parseInt(w.height) > 1 ? <Trend data={trendsf} data2={trendsf2} options={w} selection={selection} loading={loadingt} /> : null}
                            </div>
                        </div>
                    </div>
                </div> : null
            )}
        </div>
    );
}

export default Widgets;
