import React, { Component } from 'react';
import PageTitle from './common/PageTitle';
import { getObjects, deleteObject } from '../services/manageService';
import { toastSuccess, toastError } from '../services/toastService';
import logger from '../services/logService';
import Translation from '../services/translationService';
import TableForm from './common/tableForm'; 

const apiUrl = "admin/vestaboards";

class ManageVestaboards extends Component {
    state = {
        vestaboards: [],
        sortColumn: { column: 'branch_name', order: 'asc' },
        search: ''
    }

    async componentDidMount() {
        try {
            const { data: vestaboards } = await getObjects(apiUrl);
            this.setState({ vestaboards });
        } catch (ex) {
            toastError(ex?.response?.data || <Translation>unexpected-error</Translation>);
        }
    }

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    }

    onSearch = search => {
        this.setState({ search });
    }

    handleDelete = async (id) => {
        const originals = this.state.vestaboards;
        const vestaboard = originals.filter(x => x._id === id)[0];
        
        if (window.confirm(`'${vestaboard.branch_name}' verwijderen?`)) {
            // OK
            this.setState({ vestaboards: originals.filter(x => x._id !== id) });
            try {
                await deleteObject(apiUrl,id);
                toastSuccess(<Translation>delete-success</Translation>,1500);
            }
            catch (ex) {
                if (ex.response && ex.response.status === 404) {
                    toastError(<Translation>delete-error</Translation>);
                } else {
                    toastError(ex.response?.data || <Translation>delete-error</Translation>);
                    logger.log(ex);
                }
                this.setState({ vestaboards: originals });
            }
            return;
        } else {
            // CANCEL
            return;
        }
    }

    render() {
        const { vestaboards, sortColumn, search } = this.state;
        return (
            <main className="container-fluid overflow-auto">
                <PageTitle title="manage-vestaboards" search={search} onSearch={this.onSearch} addButton={'new'} />
                <TableForm data={vestaboards} keyid={"_id"} columns={[{'datalabel':'branch_name','colname':'branch', 'type':'text'},{'datalabel':'vestaboards','colname':'vestaboards-n', 'type':'object-count'},{'datalabel':'is_active','colname':'status', 'type':'boolean-status'},{'datalabel':'_id','colname':'action', 'type':'link-vestaboard'}]} sortColumn={sortColumn} onSort={this.handleSort} onDelete={this.handleDelete} search={search} />
            </main>
        );
    }
}

export default ManageVestaboards;
