import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { t } from '../../../services/translationService';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const graphoptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: 'rgb(75, 192, 192)',
    },
    {
      label: 'Dataset 3',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: 'rgb(53, 162, 235)',
    },
  ],
};

const colors = ['rgb(255, 99, 132)','rgb(53, 162, 235)','rgb(75, 192, 192)','rgb(255, 99, 132)','rgb(53, 162, 235)','rgb(75, 192, 192)','rgb(255, 99, 132)','rgb(53, 162, 235)','rgb(75, 192, 192)','rgb(255, 99, 132)','rgb(53, 162, 235)','rgb(75, 192, 192)','rgb(255, 99, 132)','rgb(53, 162, 235)','rgb(75, 192, 192)'];

export default function BarChartStacked({ data, options, label='label', amount='amount'}) {
  let values = options.x.split(",");

  let datasets = [];
  for (let i= 0; i < values.length; i++) {
    let v = values[i];
    let y = {
      label: t(v),
      data: data.map((x) => parseFloat(x[v])),
      borderColor: colors[i],
      backgroundColor: colors[i]
    }
    datasets.push(y);
  }
  
  let data1 = {
    labels: data.map((x) => x[label]),
    datasets: datasets
  };
  
  return <Bar className="chart" options={graphoptions} height={225} data={data1} />;
}
