import React from 'react';
import { toastSuccess, toastError } from '../services/toastService';
import logger from '../services/logService';
import Joi from 'joi-browser';
import Form from './common/form';
import PageTitle from './common/PageTitle';
import auth from '../services/authService';
import Translation from '../services/translationService';
import _ from 'lodash';
// import { saveProfile } from '../services/userService';
// import image from '../assets/userprofile.jpg';

class userProfile extends Form {
    state = {
        data: { full_name: '', email: '', password1: '', password2: '', language: '' },
        languages: [],
        errors: {}
    }

    schema = {
        full_name: Joi.string().required().min(1).max(255).label('Naam'),
        email: Joi.string().required().min(5).max(255).label('E-mail'),
        password1: Joi.string().empty('').max(55).label('Wachtwoord'),
        password2: Joi.string().empty('').label('Herhaal wachtwoord'),
        language: Joi.string().empty('').label('Talen')
    };
    
    async componentDidMount() {
        try {
            const { data } = await auth.getUserData();
            this.setState({ data: { full_name:data.full_name, email: data.email, password1: '', password2: '', language: data.language }, languages: data.languages });
        } catch (ex) {
            toastError(ex?.response?.data || <Translation>unexpected-error</Translation>);
        }
    }

    doSubmit = async () => {
        try {
            const { data } = this.state;
            this.props.changeLng(data.language);
            
            if (data.password1) {
                if (data.password1 !== data.password2) {
                    const errors = {...this.state.errors};
                    errors.password2 = "Wachtwoorden zijn niet hetzelfde!";
                    this.setState({ errors });
                } else {
                    const input = _.pick(data, ['full_name', 'email', 'password1', 'language']);
                    await auth.saveProfile(input);
                    toastSuccess(<Translation>saved-success</Translation>);
                    data.password1 ='';
                    data.password2 ='';
                    this.setState({ data });
                }
            } else {
                const input = _.pick(data, ['full_name', 'email', 'language']);
                await auth.saveProfile(input);
                toastSuccess(<Translation>saved-success</Translation>);
            }
        }
        catch (ex) {
            console.log('ex.response',ex.response);
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                errors.password1 = ex.response.data.replace("email","E-mail");
                this.setState({ errors });
            } else {
                toastError(ex);
                logger.log(ex);
            }
        }
    }

    render() {
        return (
            <main className="container-fluid">
                <PageTitle title="profile"/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-dp">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit} >
                                    {this.renderDisabled("email", "email", "text")}
                                    {this.renderInput("full_name", "name", "text")}
                                    {this.renderSelect("language","language" , this.state.languages )}
                                    {this.renderInput("password1", "password", "password")}
                                    {this.renderInput("password2", "passwordrepeat", "password")}
                                    <p className="p-14"><Translation>password-req</Translation>:<br/>- 8 <Translation>characters</Translation><br/>- 1 <Translation>number</Translation><br/>- 1 <Translation>s-characters</Translation><br/>- 1 <Translation>uppercase</Translation><br/>- 1 <Translation>lowercase</Translation></p>
                                    {this.renderButton("save",null,"d-md-block")}
                                </form> 
                            </div>
                        </div>

                    </div>
                    {/* <div className="col-md-6">
                        <div className="card card-dp">
                            <div className="card-body">
                                <img src={image} alt="userprofile" className="userprofile-img" />
                            </div>
                        </div>
                    </div> */}
                </div>
            </main>
        );
    }
}

export default userProfile;
