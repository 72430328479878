import React from 'react';
import _ from 'lodash';
import { toastSuccess, toastError } from '../services/toastService';
import logger from '../services/logService';
import Joi from 'joi-browser';
import Form from './common/form';
import Spinner from './common/spinner';
import PageTitle from './common/PageTitle';
import { getObject, updateObject, saveObject } from '../services/manageService';
import { pickOrOther, timeRangeColl } from "../services/calculateService";
import Translation from '../services/translationService';

const apiUrl = "admin/vestaboards";
const emptyobject = { branch_id: '', openingtime: '', closingtime:'', vestaboards: [], products: [], is_active: true };
const timerange = timeRangeColl();

class VestaboardForm extends Form {
    state = {
        data: {...emptyobject},
        id: '',
        products: [],
        product_id: 0,
        loading: true,
        errors: {}
    }

    schema = {
        branch_id: Joi.string().required().label('Branch'),
        openingtime: Joi.string().min(5).max(5).required(),
        closingtime: Joi.string().min(5).max(5).required(),
        vestaboards: Joi.array().label('Vestaboards'),
        products: Joi.array().label('Vestaboard products'),
        is_active: Joi.boolean().required().label('Active')
    };
    
    async componentDidMount() {
        try {
            const vid = window.location.href.split('managevestaboards/')[1];
            
            const { data } = await getObject(apiUrl,vid);
            if (vid === "new") {
                this.setState({ data: pickOrOther(data, emptyobject), products: data.allproducts, loading: false });
            } else {
                this.setState({ id: vid, data: pickOrOther(data, emptyobject), products: data.allproducts, loading: false });
            }
        } catch (ex) {
            toastError(ex?.response?.data || <Translation>unexpected-error</Translation>);
        }
    }

    doSubmit = async () => {
        console.log('Save:',this.state.data);
        if (window.location.href.split('managevestaboards/')[1] === 'new') {
            try {
                await saveObject(apiUrl,this.state.data);
                window.location = "/managevestaboards";
            }
            catch (ex) {
                if (ex.response) toastError(ex.response.data);
                logger.log(ex);
            }
        } else {
            try {
                await updateObject(apiUrl,this.state.id,this.state.data);
                toastSuccess(<Translation>saved-success</Translation>);
            }
            catch (ex) {
                if (ex.response) toastError(ex.response.data);
                logger.log(ex);
            }
        }
    }

    render() {
        let branches2 = _.cloneDeep(this.props.branches) || [];
        branches2 = branches2.map(b => {
            b.name = b.label;
            return b;
        });

        return (
            <main className="container-fluid">
                { this.state.loading ? <div className="loading center-dp "><Spinner /></div> : null}
                <PageTitle title="vestaboard" saveButton={{label: 'save', div: "d-md-flex justify-content-md-end", validate: this.validate, submit: this.handleSubmit}} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-dp">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit} >
                                    {this.renderSelect("branch_id", "branch" , branches2, this.state.id ? true : false )}
                                    {this.renderSelect("openingtime", "openingtime" , timerange )}
                                    {this.renderSelect("closingtime", "closingtime" , timerange )}
                                    {this.renderCheckbox("is_active", "active")}
                                    {this.renderVestaboards("vestaboards", "vestaboards")}
                                    {this.renderVestaboardproduct("products")}
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        );
    }
}

export default VestaboardForm;
