import React, { Component } from 'react';
import PageTitle from './common/PageTitle';
import { getAdminDashboards, deleteAdminDashboard } from '../services/dashboardService';
import { toastSuccess, toastError } from '../services/toastService';
import Translation from '../services/translationService';
import logger from '../services/logService';
import TableForm from './common/tableForm'; 

class ManageAdminDashboards extends Component {
    state = {
        dashboards: [],
        sortColumn: { column: 'title', order: 'asc' },
        search: ''
    }

    async componentDidMount() {
        try {
            const { data: dashboards } = await getAdminDashboards();
            this.setState({ dashboards });
        } catch (ex) {
            toastError(ex?.response?.data || <Translation>unexpected-error</Translation>);
        }
    }

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    }

    onSearch = search => {
        this.setState({ search });
    }

    handleDelete = async (id) => {
        const original = this.state.dashboards;
        const dashboard = original.filter(x => x._id === id)[0];
        
        if (window.confirm(`'${dashboard.title}' verwijderen?`)) {
            // OK
            this.setState({ dashboards: original.filter(x => x._id !== id) });
            try {
                await deleteAdminDashboard(id);
                toastSuccess(<Translation>delete-success</Translation>,1500);
            }
            catch (ex) {
                if (ex.response && ex.response.status === 404) {
                    toastError(<Translation>delete-error</Translation>);
                } else if (ex.response && ex.response.status === 400) {
                    toastError(ex.response.data);
                    logger.log(ex);
                } else {
                    logger.log(ex);
                }
                this.setState({ dashboards: original });
            }
            return;
        } else {
            // CANCEL
            return;
        }
    }

    render() {
        const { dashboards, sortColumn, search } = this.state;
        let columns =  [{'datalabel':'email','colname':'user', 'type':'text'},{'datalabel':'full_name','colname':'Gebruikersnaam', 'type':'text'},{'datalabel':'title','colname':'title', 'type':'text'},{'datalabel':'is_active','colname':'status', 'type':'boolean-status'},{'datalabel':'_id','colname':'action', 'type':'link-admindashboard'}];;
        return (
            <main className="container-fluid overflow-auto">
                <PageTitle title="manage-p-dbs" search={search} onSearch={this.onSearch} />
                <TableForm data={dashboards} keyid={"_id"} columns={columns} sortColumn={sortColumn} onSort={this.handleSort} onDelete={this.handleDelete} search={search} />
            </main>
        );
    }
}

export default ManageAdminDashboards;
