import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import DashboardTitle from '../common/dashboardTitle';
import { getData } from '../../services/dataService';
import { dateToString } from '../../services/calculateService';
import { chart } from '../common/charts/chartSelections';
import Spinner from '../common/spinner';

function Dashboard1(props) {
    const [name] = useState('Omzet per locatie');
    const [data, setData] = useState([]);
    const [dataselection, setDataselection] = useState([]);
    const [loading, setLoading] = useState(false);
    const [skip, setSkip] = useState(0);

    async function updateData() {
        try {
            console.log('updateData');
            setLoading(true);
            const { data } = await getData(dateToString(props.startDate),dateToString(props.endDate));
            setData(data['revenue_day']);
            setLoading(false);
        } catch (ex) {
            console.log(ex?.response?.data);
        }
    }

    useEffect(() => {
        // skip first because of the update of dateContext in opening new datepicker component
        if (skip) updateData();
        setSkip(skip+1);
    }, [props.endDate]);

    useEffect(() => {
        const datafiltered = _.filter(data, o => { return props.branches.includes(o.branch) } );
        setDataselection(datafiltered);
    }, [props.branches,data]);
        
    return (
        <main className="container-fluid">
            { loading ? <div className="loading center-dp "><Spinner /></div> : null}
            <DashboardTitle title={name} updateData={updateData}/>
            <div className="row">
                <div className="col-lg-7">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">Omzet per locatie</p>
                            {chart('BarChartHorizontal', dataselection)}
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">Omzet totaal</p>
                            {chart('TotalNumber', dataselection)}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Dashboard1;
